<template>
  <div>
    <el-card class="box-card" style="width: 400px;">
      <div slot="header" class="clearfix">
        <span>{{ admin.name }}</span>
      </div>
      <div>
        <div>
          <div style="display: flex;justify-content: center;">
            <el-upload
                action="api/admin/userface"
                :headers="headers"
                :data="admin"
                :show-file-list="false"
                :on-success="onSuccess">
              <img style="height: 100px;width: 100px;border-radius: 50px;"
                   title="点击修改用户头像" :src="admin.userFace" alt="">
            </el-upload>
          </div>
          <div>电话号码：
            <el-tag>{{ admin.telephone }}</el-tag>
          </div>
          <div>手机号码：
            <el-tag>{{ admin.phone }}</el-tag>
          </div>
          <div>居住地址：
            <el-tag>{{ admin.address }}</el-tag>
          </div>
          <div>用户标签：
            <el-tag type="success" v-for="(r,index) in admin.roles" :key="index">{{ r.nameZh }}</el-tag>
          </div>
        </div>
        <div style="display: flex;justify-content: space-around;margin-top: 10px;">
          <!--  @click="showUpdateAdminInfoView" -->
          <el-button type="primary" size="mini" @click="showUpdateAdminInfoView">修改信息</el-button>
          <!-- 用户修改密码 @click="showUpdatePasswordView"  -->
          <el-button type="danger" size="mini" @click="showUpdatePasswordView">修改密码</el-button>
        </div>
      </div>
    </el-card>
    <!-- 编辑用户信息 -->
    <el-dialog
        title="编辑用户信息"
        :visible.sync="adminDialogVisible"
        :before-close="closeEditView"
        width="30%">
      <div>
        <table>
          <tr>
            <td>用户昵称：</td>
            <td>
              <!-- 重新给每项赋值 editAdmin -->
              <el-input v-model="editAdmin.name"></el-input>
            </td>
          </tr>
          <tr>
            <td>电话号码：</td>
            <td>
              <el-input v-model="editAdmin.telephone"></el-input>
            </td>
          </tr>
          <tr>
            <td>手机号码：</td>
            <td>
              <el-input v-model="editAdmin.phone"></el-input>
            </td>
          </tr>
          <tr>
            <td>用户地址：</td>
            <td>
              <el-input v-model="editAdmin.address"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="adminDialogVisible = false">取 消</el-button>
        <!-- @click="updateAdminInfo" -->
        <el-button type="primary" @click="updateAdminInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
        title="更新密码"
        :visible.sync="passwordDialogVisible"
        width="30%">
      <div>
        <!-- 调整修改密码表单 -->
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="请输入旧密码" prop="oldPass">
            <el-input type="password" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="请输入新密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="checkPass">
            <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AdminInfo",
  data() {
    // 修改密码校验规则 一定要放最前面
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      headers: {
        Authorization: window.sessionStorage.getItem('tokenStr')
      },
      // 显示的对象
      admin: {
        name: '',
        userFace: '',
        telephone: '',
        phone: '',
        address: '',
        roles: {
          nameZh: '',
        }
      },
      // 编辑的对象
      editAdmin: {
        name: '',
        userFace: '',
        telephone: '',
        phone: '',
        address: '',
        roles: {
          nameZh: '',
        }
      },
      // 编辑用户信息
      adminDialogVisible: false,
      // 修改密码
      passwordDialogVisible: false,
      // 校验对象规则
      ruleForm: {
        pass: '',
        checkPass: '',
        oldPass: '',
      },
      rules: {
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        oldPass: [
          {validator: validatePass, trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.initAdmin()
  },
  methods: {
    // 预校验提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.ruleForm.adminId = this.admin.id
          this.putRequest('/admin/pass', this.ruleForm).then(resp => {
            if (resp) {
              // 更新密码成功后 退出登录
              this.postRequest('/logout') // 退出登录
              window.sessionStorage.removeItem('user')
              window.sessionStorage.removeItem('tokenStr')
              this.$store.commit('initRoutes', []) // 初始化路由 菜单 置空
              this.$router.replace('/') // 跳到登录页面

            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重围修改密码表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 修改密码弹框
    showUpdatePasswordView() {
      this.passwordDialogVisible = true
    },
    // 更新用户
    updateAdminInfo() {
      this.putRequest('/admin/info', this.editAdmin).then(resp => {
        if (resp) {
          this.adminDialogVisible = false
          this.initAdmin()
        }
      })
    },
    // 编辑用户信息弹框
    showUpdateAdminInfoView() {
      this.adminDialogVisible = true
    },
    // 关闭弹框
    closeEditView() {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.adminDialogVisible = false
            this.$message({
              type: 'info',
              message: '已取消编辑'
            });
          })
          .catch(_ => {
          });
    },
    onSuccess() {
      this.initAdmin();
    },
    // 查询用户信息
    initAdmin() {
      this.getRequest('/admin/info').then(resp => {
        if (resp) {
          this.admin = resp
          // 对象拷贝给 editAdmin
          this.editAdmin = Object.assign({}, this.admin) //
          window.sessionStorage.setItem('user', JSON.stringify(resp))
          this.$store.commit('INIT_ADMIN', resp)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>