import Vue from 'vue'
import VueRouter from 'vue-router'
// 从views层引入Login页面
import Login from "../views/Login";
import Home from "../views/Home";
import FriendChat from "../views/chat/FriendChat";
import AdminInfo from "../views/AdminInfo";


Vue.use(VueRouter)
// 解决报错: Uncaught (in promise) Error: Navigation cancelled from “/” to “/home” with a new navigation.
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

const mode = 'history'

const routes = [
    {
        // path:要跳转的路径
        path: '/',
        // name:路由名称
        name: 'Login',
        // component:路由要跳转的组件
        component: Login,
        // 不会被循环遍历出来
        hidden: true
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/chat',
                name: '在线聊天',
                component: FriendChat
            },
            {
                path: '/userinfo',
                name: '个人中心',
                component: AdminInfo
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

export default router
