<template>
  <div id="app">
    <div class="sidebar">
      <card></card>
      <list></list>
    </div>
    <div class="main">
      <message></message>
      <userText></userText>
    </div>
  </div>
</template>

<script>
import card from '../../components/chat/card'
import list from '../../components/chat/list'
import message from '../../components/chat/message'
import userText from '../../components/chat/usertext'

export default {
  name: 'FriendChat',
  data () {
    return {

    }
  },
  mounted:function() {
    this.$store.dispatch('initData');
    // 调用在线聊天室登录
    this.login();
  },
  components:{
    card,
    list,
    message,
    userText
  },
  methods:{
    // 在线聊天室登录
    login(){
      this.getRequest('/chat/login');
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  margin: 20px 100px;
  //margin: 20px auto;
  width: 800px;
  height: 600px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #c8c9c9;
  .sidebar, .main {
    height: 100%;
  }
  .sidebar {
    float: left;
    color: #f4f4f4;
    background-color: #2e3238;
    width: 200px;
  }
  .main {
    position: relative;
    overflow: hidden;
    background-color: #eee;
  }
}
</style>
