import Vue from 'vue'
import vuex from 'vuex'
import {getRequest} from "../utils/api";
// 在线聊天-导入下载的在线聊天依赖
import SockJS from 'sockjs-client'
// 在线聊天-导入下载的在线聊天依赖
import Stomp from 'stompjs'
import {Notification} from 'element-ui';

Vue.use(vuex)
const now = new Date();

const store = new vuex.Store({
    state: {
        routes: [],
        sessions: {},
        // 用来接收后端接口返回的数据
        admins: [],
        // 当前用户
        currentAdmin: JSON.parse(window.sessionStorage.getItem('user')),
        // 聊天对象,当前不选中任何用户(currentSession)
        currentSession: null,
        // currentSessionId: -1,
        // 搜索用户关键字
        filterKey: '',
        // 搜索用户关键字后显示的用户
        showAdmins: [],
        // 在线聊天-定义对象
        stomp: null,
        // 在线聊天消息集合-实现消息幂等性
        chatMap: new Map(),
        // 未读消息对象
        idDot: {}
    },

    // 与 state 同步执行,可以改变 state 对应的值的方法
    mutations: {
        // 编辑用户, 同步用户信息
        INIT_ADMIN(state, admin) {
            state.currentAdmin = admin
        },
        // 初始化路由菜单
        initRoutes(state, data) {
            state.routes = data;
        },
        // 选择聊天的对象
        changecurrentSession(state, currentSession) {
            state.currentSession = currentSession;
            // 已读消息, 小红点消失
            Vue.set(state.idDot, state.currentAdmin.username + '#' + state.currentSession.username, false)
        },
        // 初始化在线聊天消息集合
        initChatMap(state) {
            if (state.chatMap.size > 30) {
                state.chatMap.clear();
            }
        },
        // 搜索用户
        searchUser(state, filterKey) {
            // console.log(filterKey)
            state.showAdmins = [];
            state.admins.forEach(admin => {
                if (admin.name.indexOf(filterKey) !== -1 && admin !== state.currentAdmin) {
                    state.showAdmins.push(admin);
                }
            })
        },
        // 发送消息
        addMessage(state, msg) {
            let mss = state.sessions[state.currentAdmin.username + '#' + msg.to]
            if (!mss) {
                // state.sessions[state.currentAdmin.username + '#' + msg.to] = []
                Vue.set(state.sessions, state.currentAdmin.username + '#' + msg.to, [])
            }
            state.sessions[state.currentAdmin.username + '#' + msg.to].push
            ({
                content: msg.content,
                date: new Date(),
                // 不是自己
                self: !msg.notSelf
            })
        },
        // 消息持久化到localStorage中
        INIT_DATA(state) {
            // 浏览器本地的历史聊天记录
            let data = localStorage.getItem('vue-chat-session');
            //console.log(data)
            if (data) {
                state.sessions = JSON.parse(data);
            }
        },
        // 初始化数据
        INIT_ADMINS(state, data) {
            state.admins = data
            state.showAdmins = data
        }
    },
    // 异步执行
    actions: {
        // 接收消息
        // 在线聊天; context 上下文
        connect(context) {
            // 连接端点
            context.state.stomp = Stomp.over(new SockJS('/ws/ep'))
            let token = window.sessionStorage.getItem('tokenStr')
            // 把 token 放进去
            context.state.stomp.connect({'Auth-Token': token}, success => {
                // 订阅消息频道
                context.state.stomp.subscribe('/user/queue/chat', msg => {
                    let receiveMsg = JSON.parse(msg.body)
                    let uuid = receiveMsg.date + receiveMsg.content;
                    if (this.state.chatMap.get(uuid) !== 1) {
                        this.state.chatMap.set(uuid, 1);
                        // console.log(context.state.chatMap);
                        // 有消息发来，右下角 弹框提示
                        if (!context.state.currentSession || receiveMsg.from !== context.state.currentSession.username) {
                            Notification.info({
                                title: '【' + receiveMsg.fromNickName + '】发来一条消息',
                                message: receiveMsg.content.length > 10 ? receiveMsg.content.substr(0, 10) : receiveMsg.content,
                                position: 'bottom-right'
                            })
                            // 有未读消息 展示小红点
                            Vue.set(context.state.idDot, context.state.currentAdmin.username + '#' + receiveMsg.from, true)
                        }
                        // 不是自己
                        receiveMsg.notSelf = true;
                        receiveMsg.to = receiveMsg.from
                        // 接收
                        context.commit('addMessage', receiveMsg)
                    }
                })
            }, error => {
            })
        },
        initData(context) {
            context.commit('INIT_DATA')
            // 调用接口获取数据
            getRequest('/chat/admin').then(resp => {
                if (resp) {
                    context.commit('INIT_ADMINS', resp)
                }
            })
        }
    }
})

store.watch(function (state) {
    return state.sessions
}, function (val) {
    // console.log('CHANGE: ', val);
    localStorage.setItem('vue-chat-session', JSON.stringify(val));
}, {
    deep: true/*这个貌似是开启watch监测的判断,官方说明也比较模糊*/
})
export default store;