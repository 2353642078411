// 封装导出数据工具类
// 本身也是通过 axios 调用后端接口
import axios from "axios";

// 让 axios 默认带一个响应的类型 arraybuffer 二进制数组流
const service = axios.create({
    responseType: 'arraybuffer'
})

// 请求拦截器
service.interceptors.request.use(config => {
    config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr')
    return config
}, error => {
    console.log(error)
})

// 响应拦截器
service.interceptors.response.use(resp => {
    // 拿到响应头
    const headers = resp.headers
    // 判断返回流 还是 Json 字符串
    let reg = RegExp(/application\/json/)
    // 如果响应头匹配正则, 说明是一个普通请求
    // 文件响应头类型: Content-Type: application/octet-stream
    if (headers['content-type'].match(reg)) {
        // 拿到 json 字符串对象
        resp.data = unitToString(resp.data)
    } else {
        // 导入插件
        let fileDownload = require('js-file-download')
        // 相应头: content-disposition: attachment;filename=xxx.xls
        // 处理文件名
        let fileName = headers['content-disposition'].split(';')[1].split('filename=')[1]
        let contentType = headers['content-type']
        // 格式转换, 防止乱码
        fileName = decodeURIComponent(fileName)
        // 通过插件下载文件
        fileDownload(resp.data, fileName, contentType)

    }
}, error => {
    console.log(error)
})

// 处理 json 格式的转换( 编码 解码 ）
function unitToString(unitArray) {
    let encodedString = String.fromCharCode.apply(null, new Uint8Array(unitArray))
    let decodedString = decodeURIComponent(escape(encodedString))
    // 转对象
    return JSON.parse(decodedString)
}

/*
    配置请求
*/
// 预备前置路径
let base = '/api';
// 传送 json 格式的 download 请求
export const downloadRequest = (url, params) => {
    return service({
        method: 'get',
        url: `${base}${url}`,
        data: params
    })
}