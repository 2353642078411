<template>
  <div id="uesrtext">
    <textarea placeholder="按 Ctrl + Enter 发送" v-if="currentSession" v-model="content"
              v-on:keyup="addMessage" v-on:keydown="getUserLogin"></textarea>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getRequest} from "../../utils/api";

export default {
  name: 'uesrtext',
  data() {
    return {
      content: ''
    }
  },
  computed: mapState([
    'showAdmins',
    'currentSession',
  ]),
  methods: {
    // 获取用户登录状态
    getUserLogin(e) {
      // 按下 Ctrl 键
      if (e.ctrlKey && this.content.length) {
        getRequest('/chat/admin').then(resp => {
          if (resp) {
            // 按下 Ctrl 键时更新用户登录信息
            this.INIT_ADMINS(resp);
            this.currentSession.login = false;
            resp.forEach(admin => {
              // console.log(admin)
              if (admin.username === this.currentSession.username && admin.login) {
                // 发现已登录
                this.currentSession.login = true;
              }
            })

          }
        })
      }
    },
    addMessage(e) {
      // 未选择用户时发送信息提示
      // if (!this.currentSession && e.ctrlKey && e.keyCode === 13) {
      //   this.$message({
      //     type: 'info',
      //     message: '请先选择要发送信息的用户!'
      //   });
      //   return;
      // }
      // 按下 Ctrl + Enter 键
      if (e.ctrlKey && e.keyCode === 13 && this.content.length) {
        if (this.currentSession.login) {
          // 自定义发送消息
          let msgObj = {}
          // 谁发送的消息
          msgObj.to = this.currentSession.username
          // 发送什么
          msgObj.content = this.content
          this.$store.state.stomp.send('/ws/chat', {}, JSON.stringify(msgObj))
          this.$store.commit('addMessage', msgObj);
          // 发送完成后清空消息
          this.content = '';
        } else {
          this.$message({
            type: 'warning',
            message: '对方似乎未登录,刷新一下试试看!'
          });
        }
      }
    },
    // 更新用户登录信息
    INIT_ADMINS: function (resp) {
      this.$store.commit('INIT_ADMINS', resp)
    }
  }
}
</script>

<style lang="scss" scoped>
#uesrtext {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 30%;
  border-top: solid 1px #DDD;

  > textarea {
    padding: 10px;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }
}
</style>
