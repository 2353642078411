import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.css'

import {postRequest} from "./utils/api";
import {putRequest} from "./utils/api";
import {getRequest} from "./utils/api";
import {deleteRequest} from "./utils/api";
import {initMenu} from "./utils/menus";
import {downloadRequest} from "./utils/download";

// 是否在浏览器控制台打印所在环境
Vue.config.productionTip = false
Vue.use(ElementUI, {size: 'small'});
// 插件形式使用请求
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.downloadRequest = downloadRequest;

// 使用 router.beforeEach 注册一个全局前置守卫
router.beforeEach((to, from, next) => {
    // to 要去的路由; from 来自哪里的路由 ; next() 放行
    if (window.sessionStorage.getItem('tokenStr')) {
        initMenu(router, store);
        // 如果用户不存在
        if (!window.sessionStorage.getItem('user')) {
            return getRequest('/admin/info').then(resp => {
                // 判断用户信息是否存在
                if (resp) {
                    // 存入用户信息，转字符串，存入 sessionStorage
                    window.sessionStorage.setItem('user', JSON.stringify(resp));
                    // 同步用户信息 编辑用户
                    store.commit('INIT_ADMIN', resp)
                    next();
                }
            })
        }
        next();
    } else {
        // 未登录状态进入登录页
        if (to.path === '/') {
            next();
        } else {
            next('/?redirect=' + to.path);
        }
    }
})
// 实例化一个Vue
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
