<template>
  <div>
    <!-- 登录表单 -->
    <el-form :rules="rules"
             v-loading="loading"
             element-loading-text="正在登录......"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"
             ref="loginForm"
             :model="loginForm"
             class="loginContainer" @keyup.native.enter="submitLogin">
      <h3 class="loginTitle">系统登录</h3>
      <el-form-item prop="username">
        <!-- 用户名输入框 -->
        <el-input type="text" auto-complete="false" v-model="loginForm.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <!-- 密码输入框 -->
        <el-input type="password" auto-complete="false" v-model="loginForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <!-- 验证码输入框 -->
        <el-input style="width: 250px;margin-right: 5px" type="text" auto-complete="false" v-model="loginForm.code"
                  placeholder="点击图片更换验证码"></el-input>
        <!-- 验证码 -->
        <img :src="captchaUrl" @click="updateCaptcha()">
      </el-form-item>
      <!-- 记住我复选框 -->
      <el-checkbox class="loginRemember" v-model="checked">记住我</el-checkbox>
      <!-- 登录按钮 -->
      <el-button type="primary" style="width: 100%" @click="submitLogin">登录</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      // 加载中
      loading: false,
      // 验证码url
      // time参数确保点击刷新验证码出来的是不同验证码
      captchaUrl: '/api/captcha?time=' + new Date(),
      // 登录表单
      loginForm: {
        username: '',
        password: '',
        code: ''
      },
      // 记住我复选框
      checked: true,
      // 校验规则
      rules: {
        // 用户名不为空,失去焦点时触发
        username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}]
      }
    }
  },
  methods: {
    // 点击刷新验证码
    updateCaptcha() {
      this.captchaUrl = '/api/captcha?time=' + new Date();
    },
    // 提交登录
    submitLogin() {
      // 提示
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postRequest('/login', this.loginForm).then(resp => {
            // alert(JSON.stringify(resp));
            if (resp) {
              this.loading = false;
              // 存储用户 Token
              const tokenStr = resp.obj.tokenHead + ' ' + resp.obj.token;
              window.sessionStorage.setItem('tokenStr', tokenStr);
              // 跳转到首页(push: 路由跳转，可以回退到上一页,replace: 路径替换，无法回退到上一页)
              // this.$router.replace('/home');

              // 页面跳转
              // 拿到用户要跳转的路径
              let path = this.$route.query.redirect;
              // 打印用户要跳转的路径
              // console.log(path);
              // this.$router.replace((path === '/' || path === undefined) ? '/home' : path);
              // window.location.href = (path === '/' || path === undefined) ? 'http://localhost:8080/#/home' : 'http://localhost:8080/#'+path
              window.location.href = (path === '/' || path === undefined) ? 'http://yeb.cxyexe.top/#/home' : 'http://yeb.cxyexe.top/#'+path
            }
            this.loading = false;
          })
        } else {
          // 错误信息
          this.$message.error('请输入所有字段!');
          return false;
        }
      });
    }
  }
}
</script>

<style>
.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  /*属性规定背景的绘制区域 背景被裁剪到内边距框。 margin: 180 px auto;*/
  margin: 180px auto;
  width: 350px;
  padding: 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.loginTitle {
  margin: 0 auto 40px auto;
  text-align: center;
}

.loginRemember {
  text-align: left;
  margin: 0 0 15px 0;
}

/*验证码*/
.el-form-item__content {
  display: flex;
  align-items: center;
}
</style>