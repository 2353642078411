<template>
  <div id="list">
    <!-- 刷新功能, 直接绑定点击事件, 调用获取所有数据方法 -->
    <div style="height:30px;margin-left:14px">
      <el-button type="text" icon="el-icon-refresh" size="medium" @click="INIT_ADMINS">刷新</el-button>
    </div>
    <ul style="padding-left: 0;">
      <!-- <li v-for="item in admins" -->
      <li v-for="item in showAdmins"
          v-on:click="changeCurrentSession(item)"
          :class="{ active: currentSession ? item.username === currentSession.username:false }">
        <!-- :class="[item.id === currentSessionId ? 'active':'']" -->
        <!-- 未读消息提示 小红点  <el-badge is-dot> </el-badge> -->
        <el-badge is-dot :is-dot="idDot[user.username+'#'+item.username]">
          <img class="avatar" :src="item.userFace" :alt="item.name" v-if="item.login">
          <img class="avatar gray" :src="item.userFace" :alt="item.name" v-else>
        </el-badge>
        <p class="name" v-if="item.login">{{ item.name }}</p>
        <p class="name" v-else>{{ item.name }}(未登录)</p>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getRequest} from "../../utils/api";

export default {
  name: 'list',
  data() {
    return {
      user: JSON.parse(window.sessionStorage.getItem('user'))
    }
  },
  computed: mapState([
    'idDot',
    'showAdmins',
    'currentSession'
  ]),
  methods: {
    // 选择聊天的对象
    changeCurrentSession: function (currentSession) {
      // console.log(currentSession)
      // 选择聊天的对象时更新用户登录信息
      this.INIT_ADMINS();
      this.$store.commit('changecurrentSession', currentSession)
    },
    // 更新用户登录信息
    INIT_ADMINS: function () {
      // 调用接口获取数据
      getRequest('/chat/admin').then(resp => {
        if (resp) {
          this.$store.commit('INIT_ADMINS', resp)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#list {
  li {
    padding: 12px 15px;
    border-bottom: 1px solid #292C33;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
    }
  }

  li.active { /*注意这个是.不是冒号:*/
    background-color: rgba(255, 255, 255, 0.1);
  }

  .avatar {
    border-radius: 2px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }

  .name {
    display: inline-block;
    margin-left: 15px;
  }

  .gray {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
</style>
